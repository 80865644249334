import { Container, Text, Title } from '@zigurous/react-components';
import React from 'react';
import { Page } from '../components';

const metadata = {
  url: 'https://zigurous.com/terms-of-service',
  title: 'Zigurous • Terms of Service',
};

interface TermsOfServiceProps {
  location: Location;
}

export default function TermsOfService({ location }: TermsOfServiceProps) {
  return (
    <Page className="terms-of-service" location={location} metadata={metadata}>
      <Container className="margin-top-xxxl" fluid>
        <Title as="h1">Terms of Service</Title>
        <Title eyebrow>Last updated January 1st, 2021</Title>
        <br />
        <Title as="h2" size={3}>
          1. Terms
        </Title>
        <Text>
          By accessing this website you are agreeing to be bound by these terms
          of service and agree that you are responsible for the agreement with
          any applicable local laws. If you disagree with any of these terms,
          you are prohibited from accessing this site. The materials contained
          in this website are protected by copyright and trade mark law.
        </Text>
        <br />
        <Title as="h2" size={3}>
          2. Disclaimer
        </Title>
        <Text>
          All the materials on Zigurous&apos;s website are provided &quot;as
          is&quot;. Zigurous makes no warranties, may it be expressed or
          implied, therefore negates all other warranties. Furthermore, Zigurous
          does not make any representations concerning the accuracy or
          reliability of the use of the materials on its website or otherwise
          relating to such materials or any sites linked to this website.
        </Text>
        <br />
        <Title as="h2" size={3}>
          3. Limitations
        </Title>
        <Text>
          Zigurous or its suppliers will not be hold accountable for any damages
          that will arise with the use or inability to use the materials on
          Zigurous&apos;s website, even if Zigurous or an authorize
          representative of this website has been notified, orally or written,
          of the possibility of such damage. Some jurisdiction does not allow
          limitations on implied warranties or limitations of liability for
          incidental damages, these limitations may not apply to you.
        </Text>
        <br />
        <Title as="h2" size={3}>
          4. Revisions and Errata
        </Title>
        <Text>
          The materials appearing on Zigurous&apos;s website may include
          technical, typographical, or photographic errors. Zigurous will not
          promise that any of the materials in this website are accurate,
          complete, or current. Zigurous may change the materials contained on
          its website at any time without notice. Zigurous does not make any
          commitment to update the materials.
        </Text>
        <br />
        <Title as="h2" size={3}>
          5. Links
        </Title>
        <Text>
          Zigurous has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          presence of any link does not imply endorsement by Zigurous of the
          site. The use of any linked website is at the user&apos;s own risk.
        </Text>
        <br />
        <Title as="h2" size={3}>
          6. Modifications
        </Title>
        <Text>
          Zigurous may revise these Terms of Use for its website at any time
          without prior notice. By using this website, you are agreeing to be
          bound by the current version of these Terms and Conditions of Use.
        </Text>
        <br />
        <Title as="h2" size={3}>
          7. Governing Law
        </Title>
        <Text>
          Any claim related to Zigurous&apos;s website shall be governed by the
          laws of us without regards to its conflict of law provisions.
        </Text>
      </Container>
    </Page>
  );
}
